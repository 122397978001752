import React from "react"
import styled from "styled-components"
import GLink from "../../../gatsby/GLink"
import { Icon } from "semantic-ui-react"
import { skyBlue, white } from "../../constants/colors"
import { gillFont } from "../../constants/fonts"

export const ButtonIcon = styled(Icon)<{ color: string }>`
  color: ${({ color }) => color};
  height: 100% !important;
`

export const StyledButton = styled.button<{
  width?: string
  margin?: string
  color: string
  secondaryColor: string
  slim?: boolean
}>`
  ${({ width }) => width && `width: ${width}`};
  margin: ${({ margin }) => margin || 0};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: 3px solid ${({ color }) => color};
  color: ${({ color }) => color};
  padding: ${({ slim }) => (slim ? "7px 40px" : "12px 40px")};
  font-family: ${gillFont};
  font-size: ${({ slim }) => (slim ? "0.8em" : "1em")};
  line-height: 20px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.15s ease-in-out;

  ${ButtonIcon} {
    margin: 0 0 0 19px;
    padding: 0;
  }
  :hover {
    color: ${({ secondaryColor }) => secondaryColor};
    background: ${({ color }) => color};
    text-decoration: none;
    ${ButtonIcon} {
      color: ${({ secondaryColor }) => secondaryColor};
    }
  }
  :focus {
    border: 3px solid ${({ color }) => color} !important;
    outline: none;
  }
`

const StyledLink = styled(StyledButton).attrs({ as: GLink })<{ to: string }>``

interface BorderedButtonType {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  to?: string
  children?: React.ReactNode
  width?: string
  margin?: string
  color?: string
  secondaryColor?: string
  icon?: string
  className?: string
  target?: string
  slim?: boolean
}

const BorderedButton = ({
  onClick,
  to,
  children,
  width,
  margin,
  color = `${white}`,
  secondaryColor = `${skyBlue}`,
  icon,
  className = "",
  target,
  slim,
}: BorderedButtonType) => (
  <>
    {to ? (
      <StyledLink
        to={to}
        width={width}
        margin={margin}
        color={color}
        secondaryColor={secondaryColor}
        className={className}
        target={target}
        slim={slim}
      >
        {children}
        {icon && <ButtonIcon name={icon} color={color} />}
      </StyledLink>
    ) : (
      <StyledButton
        onClick={onClick}
        width={width}
        margin={margin}
        color={color}
        secondaryColor={secondaryColor}
        className={className}
        slim={slim}
      >
        {children}
        {icon && <ButtonIcon name={icon} color={color} />}
      </StyledButton>
    )}
  </>
)

export default BorderedButton
