import * as React from "react"
import { FC } from "react"
import { graphql } from "gatsby"
import Post from "../../../social-supermarket/pages/post/Post"
import PostType from "../../../social-supermarket/model/BlogType"

interface PostQueryType {
  data: {
    post: PostType
  }
  pageContext: {
    relatedPosts: PostType[]
  }
}

const PostTemplate: FC<PostQueryType> = ({ data, pageContext: { relatedPosts } }): JSX.Element => {
  const formatterRelatedPosts = relatedPosts.filter(
    post => !post.categories.some(category => category.slug === "press-releases")
  )

  return <Post post={data.post} relatedPosts={formatterRelatedPosts} />
}

export default PostTemplate

export const query = graphql`
  query ($id: String!) {
    post(id: { eq: $id }) {
      uri
      id
      title
      date
      content
      slug

      gatedContent {
        ...GatedContentFragment
      }

      bannerImage {
        srcSet
        src
        sizes
        id
        alt
      }

      featuredImage {
        srcSet
        src
        sizes
        id
        alt
      }
      categories {
        slug
      }
    }
  }
`
