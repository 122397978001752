import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import PostType from "../../model/BlogType"
import BlogPostCard from "../blog/BlogPostCard"

const Container = styled.div`
  padding: 0 10px 10px;
`

const RelatedPosts = styled.div`
  margin-top: 30px;
`

const Title = styled.h4`
  margin-left: 5px;
  margin-right: 5px;
`

interface Props {
  relatedPosts: PostType[]
}

const MinorColumn: FC<Props> = ({ relatedPosts }) =>
  relatedPosts && relatedPosts.length > 0 ? (
    <Container>
      <Title>Further Reading</Title>
      <RelatedPosts>
        {relatedPosts.map(post => (
          <BlogPostCard post={post} key={post.id} />
        ))}
      </RelatedPosts>
    </Container>
  ) : null

export default MinorColumn
