import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import PostType from "../../model/BlogType"
import { formatDate } from "../../../utils/dateUtil"

const Date = styled.h4`
  width: 100%;
  text-align: center;
`

const Content = styled.div`
  margin-top: 20px;

  img {
    height: auto !important;
  }
`

interface Props {
  post: PostType
}

const MajorColumn: FC<Props> = ({ post }) => (
  <>
    <Date>{formatDate(post.date)}</Date>
    <Content dangerouslySetInnerHTML={{ __html: post.content }} />
  </>
)

export default MajorColumn
