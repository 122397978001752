import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import BannerLayout from "../../components/layout/BannerLayout"
import ColumnsLayout from "../../../components/layout/columns/ColumnsLayout"
import PostType from "../../model/BlogType"
import MajorColumn from "./MajorColumn"
import MinorColumn from "./MinorColumn"
import LPBrochureCTABlock from "../../components/landing-page/new/components/cta/LPBrochureCTABlock"

const StyledColumnsLayout = styled(ColumnsLayout)`
  margin-top: 40px !important;
  margin-bottom: 40px !important;
`

interface Props {
  post: PostType
  relatedPosts: PostType[]
}

const Post: FC<Props> = ({ post, relatedPosts }): JSX.Element => {
  const banner = post.bannerImage
  const image = banner || post.featuredImage

  return (
    <Layout fullWidth>
      <SEO title={post.title} description={post.content}>
        <meta property="og:image" content={post.featuredImage?.src} />
        <meta property="twitter:image" content={post.featuredImage?.src} />
      </SEO>
      <BannerLayout bannerImage={image} title={post.title} />
      <StyledColumnsLayout
        renderMajor={() => <MajorColumn post={post} />}
        renderMinor={() => <MinorColumn relatedPosts={relatedPosts} />}
      />
      {post.gatedContent?.title && (
        <LPBrochureCTABlock
          title={post.gatedContent.title}
          description={post.gatedContent.description}
          buttonLabel={post.gatedContent.buttonTitle}
          buttonTo={post.gatedContent.fileUrl}
          imageSrc={post.gatedContent.image.src}
          leadCapture
          leadCaptureTitle={post.gatedContent.popupTitle}
          leadCaptureDescription={post.gatedContent.popupDescription}
          leadCaptureEventName={post.gatedContent.eventName}
          leadCaptureAnalyticsName={post.gatedContent.analyticsEventName}
        />
      )}
    </Layout>
  )
}

export default Post
