import * as React from "react"
import styled from "styled-components"
import { FC, useState } from "react"
import Part from "../../../Part"
import Subheading from "../../../Subheading"
import { lightBlack, white } from "../../../../../constants/colors"
import Title from "../../../Title"
import Description from "../../../Description"
import { StaticImage } from "gatsby-plugin-image"
import InnerBlock from "../../../InnerBlock"
import Block from "../../../Block"
import { desktop, smallMobile, tablet } from "../../../../../constants/screenSizes"
import BorderedButton from "../../../../button/BorderedButton"
import LPLeadMagnetModal from "../lead-capture/lead-magnet/LPLeadMagnetModal"

const Container = styled.div`
  background-color: ${lightBlack};
  position: relative;
  overflow: hidden;
`

const BrochureBlock = styled(Block)`
  padding-top: 70px;
  padding-bottom: 0;
`

const BrochureInnerBlock = styled(InnerBlock)`
  padding-right: 0;
`

const InsparationButtonWrapper = styled.div`
  max-width: 400px;

  @media (max-width: ${desktop}px) {
    max-width: 415px;

    a {
      font-size: 16px;
    }
  }

  @media (max-width: ${smallMobile}px) {
    max-width: 290px;
  }
`

const Left = styled(Part)`
  padding-bottom: 50px;
  padding-right: 40px;

  @media (max-width: ${desktop}px) {
    padding-bottom: 20px;
  }
`
const ChristmasBrochureButton = styled(BorderedButton)`
  text-align: left;

  margin-bottom: 20px;
  @media (max-width: ${tablet}px) {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1.33px;
  }
  @media (max-width: ${smallMobile}px) {
    max-width: 290px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1.17px;
    margin-bottom: 60px;
  }
`
const BrochureWrapper = styled(Part)`
  position: relative;
  img {
    width: 100%;
    position: absolute;
    bottom: 0;
    box-shadow: 0px 0px 10px 8px black;
  }

  @media (max-width: ${desktop}px) {
    width: 30%;
    margin-left: auto;
  }
  @media (max-width: ${tablet}px) {
    width: 40%;
    margin-left: auto;
  }
  @media (max-width: ${smallMobile}px) {
    width: 60%;
    margin-left: auto;
  }
`

interface Props {
  subheading?: string
  title: string
  description: string
  buttonLabel: string
  buttonTo: string
  imageSrc: string
  leadCapture?: boolean
  leadCaptureTitle?: string
  leadCaptureDescription?: string
  leadCaptureEventName?: string
  leadCaptureAnalyticsName?: string
}

const LPBrochureCTABlock: FC<Props> = ({
  subheading,
  title,
  description,
  buttonLabel,
  buttonTo,
  imageSrc,
  leadCapture,
  leadCaptureTitle,
  leadCaptureDescription,
  leadCaptureEventName,
  leadCaptureAnalyticsName,
}) => {
  const [showLeadCaptureModal, setShowLeadCaptureModal] = useState<boolean>(false)

  const handleClick = () => {
    if (leadCapture) {
      setShowLeadCaptureModal(true)
    } else {
      window.open(buttonTo, "_blanc")
    }
  }

  return (
    <Container>
      <BrochureBlock>
        <BrochureInnerBlock>
          <Left width="70%">
            {subheading && (
              <Subheading isBordered color={white} borderColor={white}>
                {subheading}
              </Subheading>
            )}
            <Title color={white} margin="25px 0 10px 0">
              {title}
            </Title>
            <Description color={white} margin="0 0 30px 0">
              {description}
            </Description>
            <InsparationButtonWrapper>
              <ChristmasBrochureButton onClick={handleClick} icon="arrow down" target="_blank">
                {buttonLabel}
              </ChristmasBrochureButton>
            </InsparationButtonWrapper>
          </Left>
          <BrochureWrapper width="22%">
            <img src={imageSrc} alt={"mockup"} />
          </BrochureWrapper>
        </BrochureInnerBlock>
      </BrochureBlock>
      <LPLeadMagnetModal
        config={{
          popupTitle: leadCaptureTitle,
          popupDescription: leadCaptureDescription,
          eventName: leadCaptureEventName,
          analyticsEventName: leadCaptureAnalyticsName,
          fileUrl: buttonTo,
          buttonTitle: "Download Brochure",
        }}
        show={showLeadCaptureModal}
        onClose={() => setShowLeadCaptureModal(false)}
      />
    </Container>
  )
}

export default LPBrochureCTABlock
